<template>
    <div class="personal-info">
        <div class="title-bar darker">
            <h2>Personal Information</h2>
        </div>
        <div class="info-container">
            <ul>
                <li><b>Name:</b> {{ user.name }}</li>
                <li><b>Email:</b> {{ user.email }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'personalinfo',
    props: ["user"],
}
</script>

<style>
.info-container {
  margin: 20px;
  margin-bottom: 0;
}

@media screen and (max-width: 796px) {
  .personal-info {
    margin: 20px 0;
    max-width: 1000px !important;
    flex: 1 1 150px;
  }
}
.personal-info {
  color: #444444;
  box-shadow: 0px 1px 5px #aaaaaa;
  max-width: 400px;
  margin: auto 20px;
  margin-top: 20px;
  flex-direction: column;
  text-align: left;
  font-size: 16px;
  padding-bottom: 10px;
  background-color: #fefefe;
}
</style>
