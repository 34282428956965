<template>
    <div class="disable-user">
      <div v-if="!user.isEnabled" class="title-bar darker">
          <h2>Enable {{ user.name }}</h2>
      </div>
      <div v-else class="title-bar darker">
          <h2>Disable {{ user.name }}</h2>
      </div>
      <div class="disable-container">
        <v-btn v-if="!user.isEnabled" class="white--text submit-button" :color="colors.PRIMARY" v-on:click="enableUser()">Enable</v-btn>
        <v-btn v-else class="white--text submit-button" :color="colors.PRIMARY" v-on:click="disableUser()">Disable</v-btn>
        <p>* Disabled users will not have access to their accounts and will no longer be shown in searches.</p>
      </div>
      <v-snackbar
          v-model="snackbarEnableShown"
          :color="snackbarColor"
          :timeout="snackbarTimeout"
          >{{snackbarEnableMessage}}
      </v-snackbar>
      <v-snackbar
        v-model="snackbarDisableShown"
        :color="snackbarColor"
        :timeout="snackbarTimeout"
        >{{snackbarDisableMessage}}
      </v-snackbar>
      <v-snackbar
        v-model="snackbarErrorShown"
        :color="snackbarErrorColor"
        :timeout="snackbarTimeout"
        >{{snackbarErrorMessage}}
      </v-snackbar>
    </div>
</template>

<script>
import colors from "@/colors";
import userDataAccess from '../../../dataAccess/userDataAccess';

export default {
    name: 'enabledisable',
    props: ["user"],
    data() {
      return {
        colors,
        snackbarColor: "#009b12",
        snackbarEnableShown: false,
        snackbarDisableShown: false,
        snackbarTimeout: 2000,
        snackbarEnableMessage: "Success: User Enabled",
        snackbarDisableMessage: "Success: User Disabled",
        snackbarErrorColor: "red",
        snackbarErrorShown: false,
        snackbarErrorMessage: "Error: Unable to Update Roles",
        userDAO: new userDataAccess()
      }
    },
    methods: {
      async enableUser(){
        try {
          await this.userDAO.enableUser(this.user.email, this.user.name);
          // await apiWrapper.putEnable(this.user.email, this.user.name);
        } catch (err) {
          console.log(err);
          this.snackbarErrorShown = true;
          return;
        }
        this.user.isEnabled = true;
        this.snackbarEnableShown = true;
      },
      async disableUser(){
        try {
          // await apiWrapper.disableUser(this.user.email, this.user.name);
          await this.userDAO.disableUser(this.user.email, this.user.name);
        } catch (err) {
          console.log(err);
          this.snackbarErrorShown = true;
          return;
        }
        this.user.isEnabled = false;
        this.snackbarDisableShown = true;
      }
    }
}
</script>

<style>
.disable-container {
  margin: 20px;
  margin-bottom: 0;
}

@media screen and (max-width: 796px) {
  .disable-user {
    margin: 20px 0;
    max-width: 1000px !important;
    flex: 1 1 400px;
  }
}

.disable-user {
  color: #444444;
  box-shadow: 0px 1px 5px #aaaaaa;
  max-width: 400px;
  margin: auto 20px;
  margin-top: 20px;
  flex-direction: column;
  flex: 1 1 200px;
  text-align: left;
  font-size: 16px;
  padding-bottom: 10px;
  background-color: #fefefe;
}

.submit-button {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  margin-bottom: 25px;
}
</style>

