<template>
  <div class="myaccount">
    <div class="title-bar">
      <h1 v-if="isViewingSelf === true">My Account</h1>
      <h1 v-else-if="userViewing !== null">{{ userViewing.name }}'s Account</h1>
      <v-btn v-if="isEngineer" v-on:click="goToUserSkills(user.email)" class="white--text" :color="colors.PRIMARY" style="margin-left: 20px; height: 30px">View Skills</v-btn>
    </div>
    <div class="account-content">
      <div class="col">
      <PersonalInfo
        :user="user"/>
      <EnableDisable
        v-if="canEdit && !isViewingSelf"
        :user="user"/>
      </div>
      <ModifyRoles
        :user="user"
        :isViewingSelf="isViewingSelf"/>
    </div>
  </div>
</template>

<script>
import colors from "@/colors";
import roleIdentifier from '@/roleIdentifier'
import PersonalInfo from "./components/PersonalInfo.vue";
import ModifyRoles from "./components/ModifyRoles.vue";
import EnableDisable from "./components/EnableDisable.vue";
import userDataAccess from "@/dataAccess/userDataAccess";
import roleDataAccess from '../../dataAccess/roleDataAccess';

export default {
    name: 'myaccount',
    components: {
      PersonalInfo,
      ModifyRoles,
      EnableDisable
    },
    data(){
      return {
        colors,
        userViewing: {
          name: "",
          email: "",
          roles: [],
          isEnabled: true
        },
        isViewingSelf: false,
        userDAO: new userDataAccess(),
        roleDAO: new roleDataAccess()
      }
    },
    methods: {
        async initView() {
          let response = "";
          try {
            response = await this.userDAO.getAccount();
            if(this.selectedUser === undefined || this.selectedUser === "" || this.selectedUser == response.email){
              this.isViewingSelf = true;
              this.userViewing = response;
            }
            else {
              this.isViewingSelf = false;
              try {
                response = await this.userDAO.getAccount(this.selectedUser);
              } catch (err) {
                console.log(err);
                return;
              }
            }
          } catch (err) {
            console.log(err);
            return;
          }

          this.userViewing = response;
        },
        goToUserSkills(email) {
          this.$router.push("/skills?user=" + email);
        },
    },
    computed: {
      canEdit() {
        if (this.isViewingSelf) {
          return true;
        }

        return roleIdentifier.amIAdmin();
      },
      selectedUser() {
        return this.$route.query.user;
      },
      selectedUserEmail() {
        if (this.userViewing === null) {
          return "";
        }
        return this.userViewing.email;
      },
      user(){
        return this.userViewing;
      },
      isEngineer() {
        if(this.isViewingSelf){
          return roleIdentifier.amIEngineer();
        }
        else{
          return roleIdentifier.isUserViewingEngineer();
        }
      }
    },
    mounted: async function() {
      await this.initView();
    }
}
</script>

<style scoped>
.account-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.col {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  min-width: 400px;
}

.title-bar {
  display: flex;
}

.v-btn {
  margin-top: 10px
}
</style>