<template>
        <div class="role-container">
            <div class="title-bar darker">
                <h2 v-if="isViewingSelf === true">Modify Your Roles</h2>
                <h2 v-else-if="user !== null">Modify {{ user.name }}'s Roles</h2>
            </div>
            <div class="role-select-container">
                <v-container fluid>
                    <div v-for="availableRole in availableRoles" v-bind:key="availableRole">
                        <v-checkbox hide-details v-model="newRoles" :label="availableRole" :value="availableRole" color="purple darken-3"></v-checkbox>
                    </div>
                </v-container>
            </div>
            <v-btn class="white--text submit-button" :color="colors.PRIMARY" v-on:click="updateRoles()">Apply Changes</v-btn>
            <v-snackbar
            v-model="snackbarShown"
            :color="snackbarColor"
            :timeout="snackbarTimeout"
            >{{snackbarMessage}}</v-snackbar>
            <v-snackbar
            v-model="snackbarErrorShown"
            :color="snackbarErrorColor"
            :timeout="snackbarTimeout"
            >{{snackbarErrorMessage}}</v-snackbar>

        <v-layout row justify-center>
            <v-dialog v-model="noRoleSelectedDialog" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Please select at least one role</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="purple darken-3" text @click="noRoleSelectedDialog = false">Dismiss</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>

    </div>
</template>

<script>
import colors from "@/colors";
import roleDataAccess from '../../../dataAccess/roleDataAccess';

export default {
    name: 'modifyroles',
    props: ["user", "isViewingSelf"],
    data() {
        return {
          colors,
            newRoles: [],
            storedRoles: [],
            availableRoles: [],
            noRoleSelectedDialog: false,
            snackbarColor: "#009b12",
            snackbarShown: false,
            snackbarTimeout: 2000,
            snackbarMessage: "Success: Updated Roles",
            snackbarErrorColor: "red",
            snackbarErrorShown: false,
            snackbarErrorMessage: "Error: Unable to Update Roles",
            roleDAO: new roleDataAccess()
        }
    },
    methods: {
        async updateRoles() {
          var updatedRoles = this.newRoles;
          var pastRoles = this.storedRoles;

          if(updatedRoles.length == 0){
            this.noRoleSelectedDialog = true;
          }
          else {
            try {
              var differentRoles = updatedRoles.filter(x => !pastRoles.includes(x)).concat(pastRoles.filter(x => !updatedRoles.includes(x)));
              for(var i = 0; i < differentRoles.length; i++){
                if(updatedRoles.includes(differentRoles[i])){
                  if(this.isViewingSelf){
                    await this.roleDAO.postUserRole(differentRoles[i]);
                  }
                  else {
                    await this.roleDAO.postUserRole(differentRoles[i], this.user.email);
                  }
                }
                else {
                  if(this.isViewingSelf){
                    await this.roleDAO.deleteUserRole(differentRoles[i]);
                  }
                  else {
                    await this.roleDAO.deleteUserRole(differentRoles[i], this.user.email);
                  }
                }
              }
              this.snackbarShown = true;
            } catch (err) {
              console.error(err);
              this.snackbarErrorShown = true;
              return;
            }
          }  
        },
        async initAvailableRoles() {
          try {
            this.availableRoles = await this.roleDAO.getAvailableRoles();
          } catch (err) {
            console.log(err);
            return;
          }
        },
        async initStoredRoles() {
          try {
            var queryUserEmail = this.$route.query.user
            this.storedRoles = await this.roleDAO.getRoles(queryUserEmail);
            this.newRoles.push(...this.storedRoles);
          } catch (err) {
            console.log(err);
            return;
          }
        }
    },
    mounted: async function() {
      await this.initAvailableRoles();
      await this.initStoredRoles();
    }
}
</script>


<style>
.role-select-container {
  margin: 0px 20px;
  margin-bottom: 10px;
}

@media screen and (max-width: 796px) {
  .role-container {
    margin: 20px 0;
    max-width: 1000px !important;
    width: 100%;
    flex: 1 1 400px;
  }
}

.role-container {
  color: #444444;
  box-shadow: 0px 1px 5px #aaaaaa;
  max-width: 400px;
  margin: auto 20px;
  margin-top: 32px;
  margin-left: 12px;
  flex-direction: column;
  flex: 1 1 350px;
  text-align: left;
  font-size: 16px;
  padding-bottom: 25px;
  background-color: #fefefe;
}

.submit-button {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  margin-bottom: 35px;
}
</style>